import './modules/script';
import './modules/map/mapalex';

import AOS from 'aos';
// ..
AOS.init();
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});

onElementHeightChange(document.body, function(){
  AOS.refresh();
  ScrollTrigger.refresh();
});


function onElementHeightChange(elm, callback) {
    var lastHeight = elm.clientHeight
    var newHeight;

    (function run() {
        newHeight = elm.clientHeight;
        if (lastHeight !== newHeight) callback();
        lastHeight = newHeight;

        if (elm.onElementHeightChangeTimer) {
          clearTimeout(elm.onElementHeightChangeTimer);
        }

        elm.onElementHeightChangeTimer = setTimeout(run, 150);
    })();
  }




gsap.config({trialWarn:false});
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);


/*End lannding animation*/

// ------------------------------------------- //
// Smooth scroller
let smoother = ScrollSmoother.create({
  wrapper: '#smooth-wrapper',
  content: '#smooth-content',
  smooth:2,
  smoothTouch:0.1,
  effects:true
});

var window_w=$(window).width();
if(window_w>768){
  ScrollTrigger.create({
  trigger: "#search_content",
  start: "top",
  toggleActions: "play none none pause",
  markers:false,
  pin:"#map",
  pinSpacing:false,
  });
}

let container = document.querySelector('.container');
let sections = container.querySelectorAll(".panel");

gsap.to(sections, {
  xPercent: -100 * (sections.length - 1),
  ease: "none",
  scrollTrigger: {
    trigger: ".container",
    pin: true,
    scrub: true,
    end:"+=3500",
  }
});


smoother.effects(".image_parallax img",{speed:"auto"});
