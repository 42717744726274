var mapStyle =
[
    {
        "featureType": "administrative",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#30302f"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f7f7f5"
            }
        ]
    }
];


/*id of map here*/
if ($("#maps").length > 0) {
  google.maps.event.addDomListener(window, 'load', initialize_map);
}
var map;

function initialize_map() {

  var controlPosition = google.maps.ControlPosition.RIGHT_CENTER;
  var coords_dynamic = jQuery("#maps").attr('data-coords');
  var splitedcoords = coords_dynamic.split(',');

  var myLatlng = new google.maps.LatLng(splitedcoords[0], splitedcoords[1]);

  var isDraggable = true;
  var mapOptions = {
    zoom: 11,
    gestureHandling: 'cooperative',
    center: myLatlng,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
     styles:mapStyle,
    scrollwheel: false,
  };


  map = new google.maps.Map(document.getElementById('maps'),mapOptions);

  var iconsource = 'https://demo.limecreative.gr/bagordaki/wp-content/themes/bagordaki/assets/images/marker.png';/*map pointer here*/
  var icons = {
    markericon: {
      icon: iconsource
    }
  };

  var marker = new google.maps.Marker({
    position: myLatlng,
    icon: {
    url: "https://demo.limecreative.gr/bagordaki/wp-content/themes/bagordaki/assets/images/marker.png"
    },
    map: map
  });

  marker.setAnimation(google.maps.Animation.BOUNCE);
  setTimeout(function(){ marker.setAnimation(null); }, 1000);

  //Map style is not mandatory
  var styledMap = new google.maps.StyledMapType(mapStyle, {
    name: "Styled Map"
  });

  map.mapTypes.set('mapStyle', styledMap);
  map.setMapTypeId('mapStyle')

}

/*Cluster Map*/
if ($("#map").length > 0) {
  google.maps.event.addDomListener(window, 'load', initMaps);
}
var map;

function initMaps(){

      // map options
      var controlPosition = google.maps.ControlPosition.RIGHT_CENTER;
      var coords_dynamic = jQuery("#map").attr('data-coords');
      var splitedcoords = coords_dynamic.split(',');

      var myLatlng = new google.maps.LatLng(splitedcoords[0], splitedcoords[1]);

      var mapOptions = {
        zoom: 8,
        gestureHandling: 'cooperative',
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles:mapStyle,
        scrollwheel: false,
      };

      var map = new google.maps.Map(document.getElementById('map'),mapOptions);

      // Loop through markers
      var gmarkers = [];
       for (i = 0; i < json.length; i++) {
        gmarkers.push(addMarker(json[i]));
      }

      //Add MArker function
      function addMarker(props){
        var coords_dynamic_stores=props['Coords'];
          var splitedcoords_stores = coords_dynamic_stores.split(',');
          marker = new google.maps.Marker({
            position: new google.maps.LatLng(splitedcoords_stores[0], splitedcoords_stores[1]),
            map: map,
            icon: {
              url: "https://demo.limecreative.gr/bagordaki/wp-content/themes/bagordaki/assets/images/marker.png"
          },
          });
        return marker;
      }
      var markerCluster = new MarkerClusterer(map, gmarkers,
      {
        imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
      });
    }
